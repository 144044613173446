import { createApp } from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import './assets/global.scss';
import { VueCookieNext } from 'vue-cookie-next';

createApp(App)
  .use(router)
  .use(VueCookieNext)
  .mount('#app');

VueCookieNext.config({ expire: '30d' });
