import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "Schedule" */ '../views/Schedule.vue'),
  },
  {
    path: '/prayerneeds',
    name: 'PrayerNeeds',
    component: () => import(/* webpackChunkName: "PrayerNeeds" */ '../views/PrayerNeeds.vue'),
  },
  {
    path: '/testimonies',
    name: 'Testimonies',
    component: () => import(/* webpackChunkName: "Testimonies" */ '../views/Testimonies.vue'),
  },
  {
    path: '/encouragement',
    name: 'Encouragement',
    component: () => import(/* webpackChunkName: "Encouragement" */ '../views/Encouragement.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "SignIn" */ '../views/SignIn.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "Account" */ '../views/Account.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/Admin.vue'),
  },
  {
    path: '/account.php',
    name: 'AccountPHP',
    component: () => import(/* webpackChunkName: "Account" */ '../views/Account.vue'),
  },
  {
    path: '/editschedule',
    name: 'EditSchedule',
    component: () => import(/* webpackChunkName: "EditSchedule" */ '../views/EditSchedule.vue'),
  },
  {
    path: '/editneeds',
    name: 'EditNeeds',
    component: () => import(/* webpackChunkName: "EditNeeds" */ '../views/EditNeeds.vue'),
  },
  {
    path: '/edittestimonies',
    name: 'EditTestimonies',
    component: () => import(/* webpackChunkName: "EditTestimonies" */ '../views/EditTestimonies.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
