
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

type UserInfo = {
  accountID?: number;
  name?: string;
  email?: string;
  password?: string;
  prayerRenewDate?: string;
  admin?: boolean;
};

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      userInfo: {} as UserInfo,
      connString: 'https://prodigalprayerwatch.com/',
    };
  },
  computed: {
    isLoggedIn() {
      if (this.userInfo.accountID) return true;
      else return false;
    },
  },
  methods: {
    translate(input: any, encrypt: boolean) {
      if (typeof input == 'string') {
        encrypt ? (input = this.encrypt(input)) : (input = this.decrypt(input));
      } else if (Array.isArray(input)) {
        input = input.map((value) => this.translate(value, encrypt));
      } else if (typeof input == 'object') {
        for (var key of Object.keys(input)) {
          input[key] = this.translate(input[key], encrypt);
        }
      } else {
        return input;
      }
      return input;
    },
    encrypt(input: string) {
      input = input.replaceAll("'", '~~101');
      input = input.replaceAll('"', '~~102');
      return input;
    },
    decrypt(input: string) {
      input = input.replaceAll('~~101', "'");
      input = input.replaceAll('~~102', '"');
      return input;
    },
  },
});
