<template>
  <div class="header">
    <h1 class="m-15">Prodigal Prayer Watch</h1>
    <div :class="{ nav: true, 'hide-menu': !menuOpen }">
      <router-link @click="menuOpen = false" :to="{ name: 'Home' }"><span>Home</span></router-link>
      <router-link @click="menuOpen = false" :to="{ name: 'Schedule' }"><span>Schedule</span></router-link>
      <router-link @click="menuOpen = false" :to="{ name: 'PrayerNeeds' }"><span>Prayer Needs</span></router-link>
      <router-link @click="menuOpen = false" :to="{ name: 'Testimonies' }"><span>Testimonies</span></router-link>
      <router-link @click="menuOpen = false" :to="{ name: 'Encouragement' }"><span>Encouragement</span></router-link>
      <router-link
        :class="{
          'router-link-active': $route.name == 'Account' || $route.name == 'EditNeeds' || $route.name == 'EditTestimonies' || $route.name == 'EditSchedule',
        }"
        v-if="isLoggedIn"
        @click="menuOpen = false"
        :to="{ name: 'Account' }"
        ><span>Account</span></router-link
      >
      <router-link v-else @click="menuOpen = false" :to="{ name: 'SignIn' }"><span>Sign In</span></router-link>
    </div>
    <div @click="menuOpen = !menuOpen" :class="{ hamburger: true, change: menuOpen }">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['isLoggedIn'],
  data() {
    return {
      menuOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/variables.scss';

.header {
  background: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  h1 {
    white-space: nowrap;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin: 15px;

    .bar1,
    .bar2,
    .bar3 {
      width: 28px;
      height: 3px;
      background-color: $black;
      margin: 6px 0;
      transition: 0.4s;
    }
  }
  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
  }

  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }

  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -7px);
    transform: rotate(45deg) translate(-6px, -7px);
  }
}

@media only screen and (min-width: 825px) {
  .nav {
    padding: 0px 15px;
    display: flex;
    align-items: center;

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 18px;

      span {
        white-space: nowrap;
      }
    }

    .router-link-active {
      border-bottom: 2px solid $green;
      margin-bottom: -2px;
    }
  }

  .hamburger {
    display: none !important;
  }
}
@media only screen and (max-width: 824px) {
  .nav {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 60px;
    right: 0px;
    background: $white;

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 0px;
      margin: 8px 18px;

      span {
        white-space: nowrap;
      }
    }

    .router-link-active {
      border-bottom: 2px solid $green;
      margin-bottom: -2px;
    }
  }

  .hide-menu {
    display: none !important;
  }
}
</style>
