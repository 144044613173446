
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {};
  },
  data() {
    return {
      decoded: atob('YWRtaW5AcHJvZGlnYWxwcmF5ZXJ3YXRjaC5jb20='),
    };
  },
});
